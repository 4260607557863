import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers';
import { AxiosRequestConfig } from 'axios';
import { Application, ApplicationStatus } from 'entities/Application';
import { userActions } from 'entities/User';
import { SelectOptionType } from 'shared/ui/Select/Select';

export interface FetchApplicationsParams {
  page?: number;
  periodStart?: string;
  periodEnd?: string;
  status?: ApplicationStatus;
  creator?: string;
  ordering?: string;
}

export const fetchApplicationsList = createAsyncThunk<
  { count: number; next: string | null; previous: string | null; results: Application[], totalPages: number },
  FetchApplicationsParams,
  ThunkConfig<string>
>(
  'applicationsPage/fetchApplicationsList',
  async (
    {
    page, periodStart, periodEnd, status, creator, ordering,
  },
  {
    extra, rejectWithValue, dispatch,
  },
) => {
    try {
      const statusesOptions: SelectOptionType[] = [
        {
          value: ApplicationStatus.NEW,
          text: 'Запрос создан',
        },
        {
          value: ApplicationStatus.COORDINATION,
          text: 'На согласовании',
        },
        {
          value: ApplicationStatus.PAYMENT_COORDINATION,
          text: 'Ожидается оплата',
        },
        {
          value: ApplicationStatus.IN_WORK,
          text: 'Отправлено исполнителю',
        },
        {
          value: ApplicationStatus.PROCESSED,
          text: 'Обрабатывается',
        },
        {
          value: ApplicationStatus.WAITING_FINISH,
          text: 'Ожидает подтверждения завершения',
        },
      ];
      console.log('status', status);
      const response = await extra.api.get<{ count: number; next: string | null; previous: string | null; results: Application[], totalPages: number }>(
        '/api/v1/applications/',
        { 
          params: { 
            page,
            periodStart,
            periodEnd,
            status: status === undefined ? statusesOptions.map((item) => item.value).join(',') : status,
            creator,
            ordering,
          },
        },
      );
      if (!response.data) {
        throw new Error('Ошибка получения списка заявок!');
      }
      return response.data;
    } catch (e: any) {
      if (e.response?.status === 401) {
        dispatch(userActions.logout());
      }
      return rejectWithValue(e.response?.message || 'Ошибка запроса');
    }
  },
);
