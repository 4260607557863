// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableItemHeader_tableItemHeader__jpedP {
  display: flex;
  justify-content: space-between;
  padding: 12px 43px;
  border-radius: 10px;
  border: 1px solid var(--table-border-color);
  background: var(--white-color);
}
@media (max-width: 960px) {
  .TableItemHeader_tableItemHeader__jpedP {
    padding: 10px 15px;
  }
}

.TableItemHeader_text__unkf5 {
  font: var(--font-m);
  font-weight: 700;
  line-height: 24px;
}
@media (max-width: 960px) {
  .TableItemHeader_text__unkf5 {
    font-size: var(--font-size-s);
    line-height: 15px;
  }
}

.TableItemHeader_checkbox__ZT3V3 {
  margin-right: 25px;
}

.TableItemHeader_column__jVPcf {
  display: flex;
  flex-direction: column;
}

.TableItemHeader_text-center__C4FAC {
  text-align: center;
  align-items: center;
}
.TableItemHeader_text-center__C4FAC:first-child {
  align-items: flex-start;
}
.TableItemHeader_text-center__C4FAC:last-child {
  align-items: flex-end;
}

.TableItemHeader_tableItemHeaderReset__0Du3R {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 43px;
  background: unset;
  border-radius: 10px;
}
@media (max-width: 960px) {
  .TableItemHeader_tableItemHeaderReset__0Du3R {
    padding: 10px 15px;
  }
}

.TableItemHeader_text-left__9NyGy {
  text-align: left;
}

.TableItemHeader_text-right__BZCBp {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Table/ui/TableItemHeader/TableItemHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,2CAAA;EACA,8BAAA;AAAF;AACE;EARF;IASI,kBAAA;EAEF;AACF;;AACA;EACE,mBAAA;EACA,gBAAA;EACA,iBAAA;AAEF;AADE;EAJF;IAKI,6BAAA;IACA,iBAAA;EAIF;AACF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,aAAA;EACA,sBAAA;AAGF;;AADA;EACE,kBAAA;EACA,mBAAA;AAIF;AAHE;EACE,uBAAA;AAKJ;AAHE;EACE,qBAAA;AAKJ;;AADA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AAIF;AAFE;EARF;IASI,kBAAA;EAKF;AACF;;AAHA;EACE,gBAAA;AAMF;;AAJA;EACE,iBAAA;AAOF","sourcesContent":[".tableItemHeader {\n  display: flex;\n  // align-items: flex-start;\n  justify-content: space-between;\n  padding: 12px 43px;\n  border-radius: 10px;\n  border: 1px solid var(--table-border-color);\n  background: var(--white-color);\n  @media(max-width: 960px) {\n    padding: 10px 15px;\n  }\n}\n\n.text {\n  font: var(--font-m);\n  font-weight: 700;\n  line-height: 24px;\n  @media(max-width: 960px) {\n    font-size: var(--font-size-s);\n    line-height: 15px;\n  }\n}\n\n\n.checkbox {\n  margin-right: 25px;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n}\n.text-center {\n  text-align: center;\n  align-items: center;\n  &:first-child {\n    align-items: flex-start;\n  }\n  &:last-child {\n    align-items: flex-end;\n  }\n}\n\n.tableItemHeaderReset {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 7px 43px;\n  background: unset;\n  border-radius: 10px;\n\n  @media(max-width: 960px) {\n    padding: 10px 15px;\n  }\n}\n.text-left {\n  text-align: left;\n}\n.text-right {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableItemHeader": `TableItemHeader_tableItemHeader__jpedP`,
	"text": `TableItemHeader_text__unkf5`,
	"checkbox": `TableItemHeader_checkbox__ZT3V3`,
	"column": `TableItemHeader_column__jVPcf`,
	"text-center": `TableItemHeader_text-center__C4FAC`,
	"tableItemHeaderReset": `TableItemHeader_tableItemHeaderReset__0Du3R`,
	"text-left": `TableItemHeader_text-left__9NyGy`,
	"text-right": `TableItemHeader_text-right__BZCBp`
};
export default ___CSS_LOADER_EXPORT___;
